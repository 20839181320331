<template>
  <Modal closable :visible="true" @close="$emit('close')">
    <template #title>Choose form </template>
    <div class="modal-content">
      <div v-if="clientSpecific.length">
        <div class="title">{{ organization }}</div>
        <div v-for="form in clientSpecific" :key="form.id" class="form-row">
          <Icon :name="form.type === 'docx' ? 'file-word' : 'file-pdf-box'" :title="form.description" />
          <Checkbox :value="localValue.includes(form.id)" :label="form.name" :title="form.description" @input="onInput(form.id)" />
        </div>
      </div>
      <div v-if="all.length">
        <div class="title">All forms</div>

        <div v-for="form in all" :key="form.id" class="form-row">
          <Icon :name="form.type === 'docx' ? 'file-word' : 'file-pdf-box'" :title="form.description" />
          <Checkbox :value="localValue.includes(form.id)" :label="form.name" :title="form.description" @input="onInput(form.id)" />
        </div>
      </div>
    </div>
    <template #footer>
      <div class="buttons-wrapper">
        <Button @click="$emit('close')">Cancel</Button>
        <Button color="primary" @click="onSubmit">Choose</Button>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/common/Modal';
import Icon from '@/components/common/Icon';
import Checkbox from '@/components/common/Checkbox';
import Button from '@/components/common/Button';
export default {
  components: {
    Modal,
    Icon,
    Checkbox,
    Button
  },
  props: {
    clientSpecific: {
      type: Array,
      default: () => []
    },
    all: {
      type: Array,
      default: () => []
    },
    organization: {
      type: String,
      default: null
    },
    value: {
      type: Array,
      default: () => []
    }
  },
  emits: ['close', 'submit'],
  data() {
    return {
      localValue: [...this.value]
    };
  },
  methods: {
    onSubmit() {
      this.$emit('submit', this.localValue);
    },
    onInput(id) {
      const index = this.localValue.indexOf(id);
      if (index > -1) {
        this.localValue.splice(index, 1);
      } else {
        this.localValue.push(id);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-content {
  padding: 20px;
  .form-row {
    display: grid;
    grid-template-columns: max-content max-content 1fr;
    align-items: center;
    gap: 10px;
    margin: 10px 0;
  }
}
</style>
